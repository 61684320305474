import { useRef, useState } from "react";
import { SystemProps, Box, css, Icon } from "@storyofams/react-ui";
import { Image as ToolkitImage } from "@storyofams/storyblok-toolkit";
import Link from "next/link";
import ReactPlayer from "react-player";
import { AnyStyledComponent } from "styled-components";
import { Button } from "~components/common/Button";
import { FixedRatio } from "~components/common/FixedRatio";
import { Play } from "~components/common/Icons";
import { VideoModal } from "./VideoModal";

interface ImageProps extends SystemProps {
  content?: {
    image: any;
    small?: boolean;
    borderRadius: boolean;
    videoLink?: any;
    buttonColor?: string;
    button_enabled?: boolean;
    image_link?: any;
    webp_link?: string;
    isNotCentered?: boolean;
    code_snippet?: string;
    webm_link?: string;
  };
  parentComponent?: any;
}

export const Image = (props: ImageProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const player = useRef();
  const onChangeModal = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Box
        position="relative"
        overflow="hidden"
        width="100%"
        borderTopLeftRadius={
          props.parentComponent === "recipe" && ["0.5rem", 0]
        }
        borderTopRightRadius={props.parentComponent === "recipe" && ["0.5rem"]}
        maxWidth={props.content?.small ? "880px" : "1120px"}
        // borderRadius={props.content.borderRadius && "lg"}
        css={css({
          "&&": { mx: props.content.isNotCentered ? 0 : "auto" },
          "> div": {
            width: "100%",
            height: "100%",
          },
          img: {
            width: "100%",
            maxWidth: "100%",
            borderRadius: props.content.borderRadius && "sm",
          },
          video: {
            borderRadius: props.content.borderRadius && "lg",
          },
        })}
        {...props}
      >
        <Box position="relative">
          {props?.content?.webm_link ? (
            <ReactPlayer
              loop={true}
              width={"100%"}
              style={{
                backgroundImage: "none",
                objectFit: "cover",
              }}
              muted
              on
              playing
              height={"100%"}
              stopOnUnmount={true}
              playsinline
              url={props?.content?.webm_link}
            />
          ) : props?.content?.code_snippet ? (
            <Box
              // onClick={() => posthog.capture("Clicked Greenspark Homepage")}
              {...props}
              dangerouslySetInnerHTML={{ __html: props.content.code_snippet }}
            />
          ) : props?.content?.webp_link ? (
            <img
              alt={props.content?.image?.alt || ""}
              src={props?.content?.webp_link}
              height="100%"
              loading="lazy"
            />
          ) : props?.content?.image_link?.url ? (
            <Box cursor="pointer">
              <Link href={props.content.image_link.url}>
                <ToolkitImage
                  alt={props.content?.image?.alt || ""}
                  src={props.content?.image?.filename}
                  fluid={1120}
                  fit="cover"
                  height="100%"
                  showPlaceholder={false}
                  loading="lazy"
                />
              </Link>
            </Box>
          ) : props.content?.image?.filename?.includes("webm") ||
            props.content?.image?.filename?.includes("264") ? (
            <ReactPlayer
              loop={true}
              width={"100%"}
              style={{
                backgroundImage: "none",
                objectFit: "cover",
              }}
              muted
              on
              playing
              height={"100%"}
              stopOnUnmount={true}
              playsinline
              url={props.content?.image?.filename}
            />
          ) : (
            <ToolkitImage
              alt={props.content?.image?.alt || ""}
              src={props.content?.image?.filename}
              fluid={1120}
              fit="cover"
              height="100%"
              showPlaceholder={false}
              loading="lazy"
            />
          )}

          {props.parentComponent === "recipe" &&
            props.content.button_enabled && (
              <Button
                width={234}
                backgroundColor={props.content.buttonColor}
                textColor={
                  props.content.buttonColor === "black" ? "white" : "black"
                }
                fontFamily="Barlow"
                position="absolute"
                zIndex={1}
                bottom={["28px", "60px"]}
                mx="auto"
                left={0}
                right={0}
                px={"0 !important"}
                onClick={() => setIsOpen(true)}
              >
                <Icon icon={Play} mr={2} />
                Bekijk receptvideo
              </Button>
            )}
        </Box>
      </Box>
      <VideoModal isOpen={isOpen} close={onChangeModal}>
        <ReactPlayer
          ref={player}
          url={
            props.content.videoLink?.linktype === "story"
              ? "https://www.naturalheroes.nl/".concat(
                  props.content.videoLink?.cached_url
                )
              : props.content.videoLink?.url
          }
          playing={true}
          width={"100%"}
          height={"100%"}
          config={{
            youtube: {
              playerVars: {
                modestbranding: 1,
                controls: true,
              },
            },
          }}
        />
      </VideoModal>
    </>
  );
};
