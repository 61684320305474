import React, { ReactNode } from "react";
import {
  DialogOverlay as ReachDialogOverlay,
  DialogContent as ReachDialogContent,
} from "@reach/dialog";
import { SystemProps, Icon } from "@storyofams/react-ui";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { Button } from "~components/common/Button";
import { Heading } from "~components/common/Heading";
import { Cross } from "~components/common/Icons";
import { Text } from "~components/common/Text";

const MotionOverlay = motion(ReachDialogOverlay);
const MotionDialog = motion(ReachDialogContent);

const DialogOverlay = styled(MotionOverlay)<SystemProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${(p) => p.theme.zIndices.modal};
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.4);
  opacity: 0.75;

  padding: ${(p) => p.theme.space[4]}px;

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    padding: ${(p) => p.theme.space[4]}px ${(p) => p.theme.space[2]}px 92px;
  }
  @media (max-width: 420px) {
    padding: 0;
  }
`;

const DialogContent = styled(MotionDialog)<{ children?: ReactNode }>`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  overflow: hidden;

  background: transparent;
  width: 100%;
  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    max-width: 774px;
  }
  @media (max-width: 420px) {
    max-width: none;
    max-height: none;
    width: 100%;
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  transition: color 0.2s;
  z-index: 2;
`;

const ContentWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 80%;
  width: 60%;
  margin: 0 auto;
  overflow: hidden;
  background: ${(p) => p.theme.colors.white};
  @media (max-width: 900px) {
    height: 55%;
  }
  @media (max-width: 720px) {
    height: 45%;
    width: 80%;
  }
  @media (max-width: 500px) {
    height: 35%;
    width: 80%;
  }
  @media (max-width: 450px) {
    height: 25%;
    width: 80%;
  }
`;

interface ModalProps extends SystemProps {
  isOpen: boolean;
  close(): void;
  children: ReactNode;
  ariaLabel?: string;
}

export const VideoModal = ({
  title,
  description,
  children,
  ariaLabel,
  isOpen,
  close,
}: any) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <DialogOverlay
          onDismiss={close}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 1 }}
        >
          <DialogContent
            aria-label={ariaLabel || "modal"}
            initial={{ y: -10 }}
            animate={{ y: 0 }}
            exit={{ y: -10 }}
          >
            <ContentWrapper
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 1 }}
            >
              <CloseButton
                variant="unstyled"
                onClick={close}
                aria-label="Close modal"
                mt={1.5}
                mr={1.5}
              >
                <Icon icon={Cross} fontSize={2.5} color="grey600" />
              </CloseButton>

              {title && (
                <Heading
                  variant="h2"
                  as="h2"
                  fontSize={5}
                  mb={3}
                  textAlign="center"
                >
                  {title}
                </Heading>
              )}

              {description && (
                <Text mb={4} fontSize={2.25}>
                  {description}
                </Text>
              )}

              {children}
            </ContentWrapper>
          </DialogContent>
        </DialogOverlay>
      )}
    </AnimatePresence>
  );
};
